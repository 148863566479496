import React, { Suspense } from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import { PropTypes } from "prop-types";

import "react-toastify/dist/ReactToastify.css";

import Loader from "../Layout/Loader";
import { config } from "../../config/index";

const urlBase = config.urlBase;
//const urlBase = "/biogenix";
//console.log("urlBase routes.js :: ", urlBase);

// Dynamically loaded components

const Logout = React.lazy(() => import("../logout"));
const Page404 = React.lazy(() => import("../common/page404"));

// For Admin Dashboard
const AdminHome = React.lazy(() =>
  import("../adminmodule/user-management/Home")
);
const GuestUser = React.lazy(() =>
  import("../adminmodule/user-management/guestuser")
);
const AdminUser = React.lazy(() =>
  import("../adminmodule/user-management/adminuser")
);
const AddUser = React.lazy(() =>
  import("../adminmodule/user-management/adduser")
);
const EditUser = React.lazy(() =>
  import("../adminmodule/user-management/edituser")
);
const AppointmentHome = React.lazy(() =>
  import("../adminmodule/appointment-management/Home")
);
const AdminChangePassword = React.lazy(() =>
  import("../adminmodule/changepassword")
);

const Search = React.lazy(() => import("../adminmodule/common/search"));
const AppointmentSearchView = React.lazy(() =>
  import("../adminmodule/common/appointmentsearchview")
);
const DefaultRoot = ({ component: Component, path, exact, ...rest }) => {
  return (
    <Route
      path={path}
      exact={exact}
      render={(props) => {
        const { match: { params: urlProps } = {} } = props;
        const {
          location: { state: navigationStateProps },
        } = props;

        return <Component {...rest} {...urlProps} {...navigationStateProps} />;
      }}
    />
  );
};

const getAdminRoutes = (props) => {
  return (
    <Suspense fallback={<Loader />}>
      <BrowserRouter basename={`${urlBase}`}>
        <Switch>
          <DefaultRoot
            component={AdminHome}
            path="/admin/patientlist"
            exact={true}
          />
          <DefaultRoot
            component={GuestUser}
            path="/admin/guestuser"
            exact={true}
          />
          <DefaultRoot component={AdminUser} path="/admin" exact={true} />
          <DefaultRoot component={AddUser} path="/admin/adduser" exact={true} />
          <DefaultRoot
            component={EditUser}
            path="/admin/edituser/:id"
            exact={true}
          />
          <DefaultRoot
            component={AppointmentHome}
            path="/admin/appointment"
            exact={true}
          />
          <DefaultRoot
            component={AdminChangePassword}
            path="/admin/changepassword"
            exact={true}
          />
          <DefaultRoot component={Search} path="/admin/search" exact={true} />
          <DefaultRoot
            component={AppointmentSearchView}
            path="/admin/appointmentsearchview"
            exact={true}
          />
          <DefaultRoot
            component={Logout}
            path="(/admin/logout|/)"
            exact={true}
          />
          <DefaultRoot component={Page404} />
        </Switch>
      </BrowserRouter>
    </Suspense>
  );
};

getAdminRoutes.propTypes = {
  history: PropTypes.array,
};

export default getAdminRoutes;
