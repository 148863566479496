import React from "react";
import ReactDOM from "react-dom";
import { ConnectedRouter } from "connected-react-router";
import { createBrowserHistory } from "history";
import { Provider } from "react-redux";
import "font-awesome/css/font-awesome.min.css";
import { LocalizeProvider } from "react-localize-redux";
import Cookies from "universal-cookie";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import getRoutes from "./app/routes/routes";
import getAdminRoutes from "./app/routes/getAdminRoutes";
import configureStore from "./app/store/configureStore";

const history = createBrowserHistory();
const store = configureStore(history);

const App = (props) => {
  const cookies = new Cookies();
  const currentUser = cookies.get("currentUser");
  const { authorities = [] } = currentUser || {};
  const isAdmin = authorities.some(
    (user) => user === "ROLE_ADMIN" || user === "ROLE_ADMIN_READ" || user === "ROLE_USER"
  );
  const isCustomer = authorities.some((user) => user === "ROLE_CUSTOMER");
  const routes = isAdmin ? getAdminRoutes() : getRoutes({ isCustomer });

  return (
    <LocalizeProvider>
      <Provider store={store}>
        <ConnectedRouter history={history}>{routes}</ConnectedRouter>
      </Provider>
    </LocalizeProvider>
  );
};

ReactDOM.render(<App />, document.getElementById("root"));
