import React, { Suspense } from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import { PropTypes } from "prop-types";
import { ToastContainer } from "react-toastify";

import "react-toastify/dist/ReactToastify.css";

import Loader from "../Layout/Loader";
import { config } from "../../config/index";

const urlBase = config.urlBase;
//const urlBase = "/biogenix";
//console.log("urlBase routes.js :: ", urlBase);

// Dynamically loaded components

const CommonHeader = React.lazy(() => import("../common/commonHeader"));
const NavBig = React.lazy(() => import("../common/navBig"));
const Home = React.lazy(() => import("../home"));
const About = React.lazy(() => import("../aboutUs/AboutUs"));
const Milestone = React.lazy(() => import("../milestone"));
const Genomics = React.lazy(() => import("../genomics"));
const GenomicsInnerPages = React.lazy(() => import("../genomics/genomicsInnerPages"));
const TestsInnerPages = React.lazy(() => import("../genomics/testsInnerPages"));
const GenomicsPressReleases = React.lazy(() => import("../genomics/pressreleases"));
const ContactUs = React.lazy(() => import("../contactUs/ContactUs"));
// const Appointment = React.lazy(() => import("../appointment"));
const News = React.lazy(() => import("../news/News"));
const OurTeamPage = React.lazy(() => import("../ourTeamPage"));
const Faq = React.lazy(() => import("../faq/Faq"));
const Policy = React.lazy(() => import("../common/policy"));
const Ticker = React.lazy(() => import("../ticker/Ticker"));
const Footer = React.lazy(() => import("../footer/Footer"));
const Logout = React.lazy(() => import("../logout"));
const ETIHAD = React.lazy(() => import("../etihad"));
const G42healthcare = React.lazy(() => import("../g42healthcare"));
const TestCookiePage = React.lazy(() => import("../common/cookie/testCookiePage"));
const Page404 = React.lazy(() => import("../common/page404"));

const Profile = React.lazy(() => import("../userprofile"));
const EditProfile = React.lazy(() => import("../userprofile/editprofile"));
const ChangePassword = React.lazy(() => import("../userprofile/changepassword"));

const DefaultRoot = ({ component: Component, path, exact, ...rest }) => {
  const { noHeader, noFooter } = rest;
  return (
    <Route
      path={path}
      exact={exact}
      render={(props) => {
        const { match: { params: urlProps } = {} } = props;
        const {
          location: { state: navigationStateProps },
        } = props;

        return (
          <>
            {!noHeader && (
              <>
                <CommonHeader />
                <NavBig />
              </>
            )}
            <Component {...rest} {...urlProps} {...navigationStateProps} />
            {!noFooter && (
              <>
                <Ticker />
                <Footer />
              </>
            )}
          </>
        );
      }}
    />
  );
};

const getRoutes = (props) => {
  return (
    <Suspense fallback={<Loader />}>
      <BrowserRouter basename={`${urlBase}`}>
        <Switch>
          <DefaultRoot component={Home} exact={true} path="/" noHeader />
          <DefaultRoot component={About} path="/aboutUs" />
          <DefaultRoot component={Milestone} path="/milestone" />
          <DefaultRoot component={News} path="/news" />
          <DefaultRoot component={Faq} path="/faq" />
          <DefaultRoot component={OurTeamPage} path="/OurTeam" />
          <DefaultRoot component={ContactUs} path="/contactUs" />
          {/* <DefaultRoot component={Appointment} path="/appointment" /> */}
          <DefaultRoot component={ETIHAD} path="/etihad" />
          <DefaultRoot component={Genomics} path="/genomics/" />
          <DefaultRoot component={GenomicsInnerPages} path="/(carrierScreening|hereditary|rareMetabolic|oncology)/" />
          <DefaultRoot component={GenomicsPressReleases} path="/(genomics-news1|genomics-news2|genomics-news3|genomics-news4|genomics-news5|genomics-news6)" />
          <DefaultRoot component={TestsInnerPages} path="/(cardiologl-panels|cytogentics|dermatology-panels|ear-nose-throat|endocrinology-panel|hematology|immunology|sub-fertility-panel|invasive-prenatal-panel|nephrology-panels|neurology-panels|oncology-panel|ophthamology-panels|metabolic-panels|paediatric-dysmorphology-panels)" />
          <DefaultRoot component={TestCookiePage} path="/testCookiePage" />
          {/* {props.isCustomer && (
            <>
              <DefaultRoot component={Profile} path="/profile" noHeader />
              <DefaultRoot component={EditProfile} path="/editprofile" noHeader />
              <DefaultRoot component={ChangePassword} path="/changepassword" noHeader />
              <DefaultRoot component={Logout} path="/logout" />
            </>
          )} */}
          <DefaultRoot component={G42healthcare} path="/g42healthcare" noFooter noHeader />
          <DefaultRoot component={Policy} path="/(CookiesPolicy|TermsAndConditions|PrivacyPolicy)/" />
          <DefaultRoot component={Page404} noHeader />
        </Switch>
      </BrowserRouter>
      <ToastContainer />
    </Suspense>
  );
};

getRoutes.propTypes = {
  history: PropTypes.array,
};

export default getRoutes;
